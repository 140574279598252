



























































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import { unselectFirstElement, duplicateDataItems } from '@/helper/functions';
import { PHOTOVOLTAIK_INSURANCE } from '@/constant/constants';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

const variantItem = {
  haftzeit: '',
  haftzeitSelect: '',
  zeitlicheSelbstbeteiligung: '',
  zeitlicheSelbstbeteiligungSelect: '',
  weiterfutterungskosten: '',
};

const versicherteLeistungenItem = {
  isSelected: false,
  num: 0,
  opened: true,
  openedVariants: true,
  versicherteLeistungen: {
    versicherteLeistung: '',
    versicherungssumme: null,
    ausfallzifferEinzelausfall: null,
    ausfallzifferDoppelausfall: null,
    ausfallzifferDreifachausfall: null,
    Beitragssatz: null,
  },
  versicherteLeistungenVariants: {
    variant1: {
      ...variantItem,
    },
    variant2: {
      ...variantItem,
    },
    variant3: {
      ...variantItem,
    },
  },
};

const positionenItem = {
  isSelected: false,
  num: 0,
  versichertePositionen: {
    versicherteMaschine: '',
    MKZ: '',
  },
  versicherteLeistungenItems: [
    versicherteLeistungenItem,
  ],
};

const positionenItemType = 1;

const versicherteLeistungenType = 2;

const fullTabItem = {
  opened: true,
  angebotserfassungBU: {
    eingabelogik: '',
  },
  gesamtversicherungssummen: [
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
    {
      versicherteLeistungenDropdown: {
        value: '',
        label: 'Versicherte Leistungen',
      },
      versicherteLeistungenValue: '',
    },
  ],
  positionenItems: [
    positionenItem,
  ],
};

export default Vue.extend({
  name: 'photovoltaics-insurance-BU-ABE',
  mixins: [dataChangeMixin],
  components: {
    vSelect,
  },
  data() {
    return {
      key: PHOTOVOLTAIK_INSURANCE,
      name: 'photovoltaics-insurance-BU-ABE',
      backUrl: 'photovoltaics-insurance-EEPV',
      submitedForm: false,
      nextTabId: 4,
      gesamtversicherungssummenValues: [
        {
          value: '',
          label: 'Versicherte Leistungen',
        },
        {
          value: 'value 1',
          label: 'label 1',
        },
        {
          value: 'value 2',
          label: 'label 2',
        },
      ],
      tabItems: [
        JSON.parse(JSON.stringify(fullTabItem)),
      ],
      byPositionenItemType: positionenItemType,
      byVersicherteLeistungenType: versicherteLeistungenType,
      pdfStyles: false,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id', 'productId'],
  methods: {
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 2);
    },
    addMainItem() {
      const item = JSON.parse(JSON.stringify(fullTabItem));
      this.tabItems.push({
        ...item,
      });
    },
    duplicateVersicherteLeistungenItemItem(index: number, positionenIndex: number, fillData: boolean) {
      duplicateDataItems(
        versicherteLeistungenItem,
        this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems,
        fillData,
      );
    },
    deleteVersicherteLeistungenItemItem(index: number, positionenIndex: number) {
      unselectFirstElement(this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems);

      this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems =
        this.tabItems[index].positionenItems[positionenIndex].versicherteLeistungenItems.filter((item) => !item.isSelected);
    },
    setValues(insuranceData: any) {
      this.gesamtversicherungssummenValues = insuranceData.gesamtversicherungssummenValues;
      this.tabItems = insuranceData.tabItems;
      this.byPositionenItemType = insuranceData.byPositionenItemType;
      this.byVersicherteLeistungenType = insuranceData.byVersicherteLeistungenType;
    },
    onSubmit(goNextTab = true) {
      const data = {
        gesamtversicherungssummenValues: this.gesamtversicherungssummenValues,
        tabItems: this.tabItems,
        byPositionenItemType: this.byPositionenItemType,
        byVersicherteLeistungenType: this.byVersicherteLeistungenType,
      };

      this.$emit('insurance-forms-data-change', data, 'buAbe', goNextTab ? this.nextTabId : false);
    },
    deleteRows(tabId: number, type: number) {
      switch (type) {
        case this.byPositionenItemType:
          unselectFirstElement(this.tabItems[tabId].positionenItems);

          this.tabItems[tabId].positionenItems = this.tabItems[tabId].positionenItems.filter((item) => !item.isSelected);
          break;
        default:
          console.log('Wrong type');
      }
    },
    dublicateRow(tabId: number, type: number, fillData: boolean) {
      switch (type) {
        case this.byPositionenItemType:
          duplicateDataItems(positionenItem, this.tabItems[tabId].positionenItems, fillData);
          break;
        default:
          console.log('Wrong type');
      }
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Photovoltaics-BU-ABE-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
  },
  computed: {
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData && this.insuranceData.insuranceFormsData.buAbe) {
      this.setValues(this.insuranceData.insuranceFormsData.buAbe);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (!this.isPCUser && !this.submitedForm) {
      this.onSubmit(false);
    }
  },
});
